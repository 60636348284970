import Modal from "react-overlays/Modal";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import "./StripeModal.css";
import { ref, push } from "firebase/database";
// import WebFlowComponent from "../Pages/WebFlowComponent";
import StripePricing  from "./StripePricing";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

function SubscribeModal({ user }) {
  const [show, setShow] = useState(false);
  const [input, setInput] = useState("");

  // Create the backdrop component for the modal
  const renderBackdrop = (props) => <Backdrop {...props} />;

  // Connect to the firebase database
//   const chatRef = ref(db, "bugs");

//   function handleSubmit() {
//     console.log(`${input}`);
//     // Push the bug to database with a timestamp
//     push(chatRef, {
//       bug: input,
//       timestamp: Date.now(),
//     })
//       .then(() => {
//         console.log("Bug Submitted");
//         setInput("");
//       })
//       .catch((error) => {
//         console.log("The write failed...", error);
//       });
//   }

  return (

    <div >
        {/* <p> test test this is reallty werid</p> */}
      {/* Open modal button */}
      {user && (
        <button
          type="button"
          className="text-mode-button-s"
          onClick={() => setShow(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="store-button-svg"
            style={{ width: 23, height: 23, marginRight: 10 }}
          >
            <path
              d="M171.7 191.1h232.6L322.7 35.07c-6.1-11.76-1.5-26.249 10.2-32.364 11.8-6.115 26.3-1.539 32.4 10.224l93.1 178.17H544c17.7 0 32 15.2 32 32 0 18.6-14.3 32-32 32l-51.9 208.4c-8 28.5-32.7 48.5-62.1 48.5H145.1c-28.5 0-54.1-20-61.22-48.5L32 255.1c-17.67 0-32-13.4-32-32 0-16.8 14.33-32 32-32h85.6l93.1-178.17c6.1-11.763 20.6-16.339 32.4-10.224 11.7 6.115 16.3 20.604 10.2 32.364L171.7 191.1zm19.4 112c0-8-6.3-16-16-16-7.9 0-16 8-16 16v96c0 9.7 8.1 16 16 16 9.7 0 16-6.3 16-16v-96zm80 0v96c0 9.7 8.1 16 16 16 9.7 0 16.9-6.3 16.9-16v-96c0-8-7.2-16-16.9-16-7.9 0-16 8-16 16zm144.9 0c0-8-7.2-16-16-16s-16 8-16 16v96c0 9.7 7.2 16 16 16s16-6.3 16-16v-96z"
              fill="#fff"
              />
            </svg>
        Subscribe
        </button>
      )}

      {/* The Modal component */}
      {/* <div className="modal-container"></div> */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        renderBackdrop={renderBackdrop}
        // aria-labelledby="modal-label"
        className="modal"
        
      >
        {/* <div className="modal-inner-container"> */}
          {/* Modal label */}
          {/* <div className="store-button-container">
            <p> store btn </p>
          <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="store-button-svg"
            style={{ width: 23, height: 23, marginRight: 10 }}
          >
            <path
              d="M171.7 191.1h232.6L322.7 35.07c-6.1-11.76-1.5-26.249 10.2-32.364 11.8-6.115 26.3-1.539 32.4 10.224l93.1 178.17H544c17.7 0 32 15.2 32 32 0 18.6-14.3 32-32 32l-51.9 208.4c-8 28.5-32.7 48.5-62.1 48.5H145.1c-28.5 0-54.1-20-61.22-48.5L32 255.1c-17.67 0-32-13.4-32-32 0-16.8 14.33-32 32-32h85.6l93.1-178.17c6.1-11.763 20.6-16.339 32.4-10.224 11.7 6.115 16.3 20.604 10.2 32.364L171.7 191.1zm19.4 112c0-8-6.3-16-16-16-7.9 0-16 8-16 16v96c0 9.7 8.1 16 16 16 9.7 0 16-6.3 16-16v-96zm80 0v96c0 9.7 8.1 16 16 16 9.7 0 16.9-6.3 16.9-16v-96c0-8-7.2-16-16.9-16-7.9 0-16 8-16 16zm144.9 0c0-8-7.2-16-16-16s-16 8-16 16v96c0 9.7 7.2 16 16 16s16-6.3 16-16v-96z"
              fill="#fff"
              />
            </svg>
            Store test btn
          </>
        </div> */}

          
          {/* <h3 className="modal-label">Subscribe</h3> */}
          {/* Introduction to bug report */}
          <StripePricing></StripePricing>
         {/* <WebFlowComponent/> */}
          {/* The bug report form */}
          {/* <form onSubmit={handleSubmit} className="bug-form">
            <textarea
              className="bug-form-textarea"
              maxLength="3000"
              required={true}
              placeholder="Describe the bug"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            ></textarea>
            <button className="submit-bug-button">Submit</button>
          </form> */}
        {/* </div> */}
      </Modal>
    </div>
  );
}

export default SubscribeModal;
