import React from "react";
import "./FreeTrial.css";

function FreeTrial_or_Subscribed({txt}) {
  return (
    <>
      <div className="free-trial-text">{txt}</div>
    </>
  );
}

export default FreeTrial_or_Subscribed

