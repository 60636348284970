import "./PlusButton.css";
import React from "react";

const PlusButton = () => {
  function handleClick() {
    //
    // Sets a new conversation id, clears users input and messages,
    // also removes error messages
    alert(" Available even when demand is high\n Faster response speed\nControl over censoredness\nPlus is for subcribed members only");
  }

  return (
    <button
      className="plus-button"
      onClick={handleClick}
      // Disabled while a message is loading
      //disabled={isLoading}
    >
      <span> PLUS</span> 
    </button>
  );
};

export default PlusButton;