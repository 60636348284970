// // DarkAlertModal.js
// import React from 'react';
// import Modal from 'react-modal';
// import styled from 'styled-components';

// // Modal styling
// const customStyles = {
//   overlay: {
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   },
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     background: '#232323',
//     borderRadius: '10px',
//     padding: '20px',
//     color: '#ffffff',
//     zIndex: 99999999,
//   },
// };

// // Styled button
// const StyledButton = styled.button`
//   background-color: #4caf50;
//   border: none;
//   color: white;
//   padding: 10px 20px;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
//   font-size: 16px;
//   margin: 10px 2px;
//   cursor: pointer;
//   border-radius: 5px;
// `;

// Modal.setAppElement('#root');

// const DarkAlertModal = ({ isOpen, onRequestClose, content }) => {
//   return (
// //    { console.log('running')}
//         // <p> test </p>
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       style={customStyles}
//       contentLabel="Dark Alert Modal"
//     >
//       <h2>{'test'}</h2>
//       <p>{content}</p>
//       <StyledButton onClick={onRequestClose}>Close</StyledButton>
//     </Modal>
//   );
// };

// export default DarkAlertModal;


import Modal from "react-overlays/Modal";
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import "./BugReportModal.css";
import { ref, push, get } from "firebase/database";
import { collection, getDocs } from "firebase/firestore";
import { m } from "framer-motion";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

function MsgHistoryModal({ isOpen, onRequestClose, content, message, conversationId, userId, db}) {
// function MsgHistoryModal({ iuser }) {
  const [show, setShow] = useState(false);
  const [input, setInput] = useState("");

//   console.log('msg in modal => ', message)
  const [chat_content_from_db, set_chat_content_from_db] = useState([])

  
  

  // Create the backdrop component for the modal
  const renderBackdrop = (props) => <Backdrop {...props} />;

  // Connect to the firebase database
//    const chatRef = ref(db, "bugs");

//   function handleSubmit() {
//     console.log(`${input}`);
//     // Push the bug to database with a timestamp
//     push(chatRef, {
//       bug: input,
//       timestamp: Date.now(),
//     })
//       .then(() => {
//         console.log("Bug Submitted");
//         setInput("");
//       })
//       .catch((error) => {
//         console.log("The write failed...", error);
//       });
//   }

useEffect(() => {

    // const userRef = ref(db, `trials/${user.uid}`);
    
    // get(userRef).then((snapshot) => {
    //   if (snapshot.val() === null) return;
      
    const chatRef = ref(db, `messages/${userId}/${conversationId}`);
    get(chatRef).then((snapshot) => {
        // console.log("No data available for chat content: ", conversationId);
        console.log("Snapshot of convo:  ");
        console.log("for user: ", userId, " and convo: ", conversationId);
        console.log(snapshot.val())
        const messages = Object.values(snapshot.val()).map((item) => {
            const { message, timestamp, user } = item;
            return {
              message,
              timestamp,
              user,
            };
          });
        console.log('chat from db: ,', messages)
        set_chat_content_from_db(messages)
          
    }).catch((error) => {
        console.error("snapshot error ", error);
    });



    // get(ref(db, `messages/${userId}/${conversationId}`)).then(
    //     (snapshot) => {
    //     if (snapshot.exists()) {
    //         console.log(snapshot.val());
    //         set_chat_content_from_db(snapshot.val())
    //         console.log('chat content from db: ', snapshot.val())
    //     } else {
    //         console.log("No data available for chat content: ", conversationId);
    //         console.log(snapshot.val())
    //     }
    // }).catch((error) => {
    //     console.error(error);
    // });
}, [])

  return (
    <div className="modal-example">
      {/* Open modal button
    <div className="modal-example">
      {/* Open modal button */}


    <style>{`
            .chat-content {
            font-family: "Open Sans", sans-serif;
            font-size: 12px;
            }

            .chat-message {
            margin-bottom: 10px;
            }

            .chat-user {
            font-weight: bold;
            }

            .chat-text {
            /* Add any specific styles for the message text */
            }

            .chat-timestamp {
            font-size: 10px;
            color: #999;
            margin-top: 2px;
            }
        `}</style>


      {/* The Modal component */}
      <Modal
        show={isOpen}
        onHide={onRequestClose}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
        className="modal-fixed modal"
        style={{backgroundColor: "#000 !important", position: "fixed !important"}}
        id="bug-modal"
      >
        <div className="modal-inner-container">
          {/* Modal label */}
          {/* <h3 className="modal-label">Report Bug</h3> */}
          {/* Introduction to bug report */}
          <div className="chat-content">
            {chat_content_from_db ? (
                <>
                {console.log("Data in chat_content_from_db:", chat_content_from_db)}
                {chat_content_from_db.map((msg, index) => (
                    <div key={index} className="chat-message">
                    <span className="chat-user">{msg.user}: </span>
                    <span className="chat-text">
                        {typeof msg.message === "object" ? JSON.stringify(msg.message) : msg.message}
                    </span>
                    <div className="chat-timestamp">
                        {new Date(msg.timestamp).toLocaleString()}
                    </div>
                    </div>
                ))}
                </>
            ) : null}
            </div>


        {/* <h3 style={{fontSize: "12px", fontFamily: '"Open Sans", sans-serif'}}>
            {/* {chat_content_from_db} */}
          {/* </h3>
            <br></br> */} 
          {/* <p style={{fontSize: "10px", fontFamily: '"Open Sans", sans-serif'}}>
            {new Date(message.timestamp).toLocaleDateString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'})}
          </p> */}
            <br></br>
            <br></br>
          <p style={{fontSize: "8px", fontFamily:'"Open Sans", sans-serif'}}> Note: the app does not currently store chat history, only your 1st message</p>

          {/* Tips for submitting a bug report */}
          {/* <ul className="tips-for-submitting">
            <li>Be specific about every step that leads to the problem.</li>
            <li>
              Avoid typos or abbreviations, which make bugs much harder to track
              down.
            </li>
            <li>
              Include how long you have been seeing the problem, and how often
              (if more than once).
            </li>
          </ul> */}

          {/* The bug report form */}
          {/* <form onSubmit={handleSubmit} className="bug-form">
            <textarea
              className="bug-form-textarea"
              maxLength="3000"
              required={true}
              placeholder="Describe the bug"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            ></textarea>
            <button className="submit-bug-button">Submit</button>
          </form> */}
        </div>
      </Modal>
    </div>
  );
}

export default MsgHistoryModal;



// function MsgHistoryModal({ user }) {
// export function UnsubscribeModal({ isOpen, onRequestClose, content, message }) {
export function UnsubscribeModal({ user, db }) {
    const [show, setShow] = useState(false);
    const [input, setInput] = useState("");
    const renderBackdrop = (props) => <Backdrop {...props} />;
    const [subscription, setSubscription] = useState(null);

    // Load user's subscription details
    const loadSubscription = async () => {
      const ref = await getDocs(
        collection(db, `customers/${user.uid}/subscriptions`)
      );
      // Set subscription details in state
      ref.forEach(async (doc) => {
        setSubscription({
          role: doc.data().role,
          current_period_end: doc.data().current_period_end,
          current_period_start: doc.data().current_period_start,
          ended_at: doc.data().ended_at,
          status: doc.data().status,
        });
      });
    };
  
    // Load subscription details when component loads
    useEffect(() => {
      loadSubscription();
    }, []);
  

    // const [show, setShow] = useState(false);

    // console.log('msg in modal => ', message)
    return (
        <>
    {(!subscription?.ended_at && subscription?.status !== "incomplete") ||
    subscription?.status === "complete" ? (
      <div className="modal-example">
        {user && (
          <button
            type="button"
            className="open-modal-button"
            style={{top: "8%", zIndex: -1 }}
            onClick={() => setShow(true)}
          >
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="bug-svg"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
            </svg>
            Manage Subscription
          </button>
        )}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          renderBackdrop={renderBackdrop}
          aria-labelledby="modal-label"
          className="modal-fixed modal"
          style={{ backgroundColor: "#000 !important", position: "fixed !important"}}
          id="bug-modal"
        >
          <div className="modal-inner-container">
            <a
              className="sub-button"
              href="https://billing.stripe.com/p/login/bIY00d4oH2rB9DaeUU" //updated mar 21 - B
              target="_blank"
              style={{ alignContent: "center", fontFamily: '"Open Sans", sans-serif', fontSize: "14px" }}
            >
              Click to manage your subscription on Stripe
            </a>
          </div>
        </Modal>
      </div>
    ) : null}
    </>
 )
}   



