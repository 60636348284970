import React, { useState, useEffect } from 'react';
import './StripeCard.css';

function Card({ period, price, loadCheckout }) {
  const [animationKey, setAnimationKey] = useState(0);
  const [btnText, setBtnText] = useState('Subscribe');

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [period]);

  // Add this function to handle button click
  const handleButtonClick = () => {
    if (loadCheckout) {
      loadCheckout(period);
    }
    setBtnText('Proceeding to checkout...')
    setTimeout(() => {
      setBtnText('Subscribe')
    }
    , 5000)
  };


  return (
    <div key={animationKey} className={'Card'}>
      <h2 className={'CardTitle'}>
        {period === 'weekly' ? 'Weekly' : null}
        {period === 'monthly' ? 'Monthly' : null}
        {period === 'quarterly' ? '3 Months' : null}
      </h2>
      <div className={'CardPrice'}>
        <span>${price} </span>
        <span>USD</span>
      </div>
      <div className={'CardPeriod'}>
        <span>PER </span>
        <span>
          {period === 'weekly' ? 'WEEK' : null}
          {period === 'monthly' ? 'MONTH' : null}
          {period === 'quarterly' ? '3 MONTHS' : null}
        </span>
      </div>
      <button onClick={handleButtonClick}>{btnText}</button>
    </div>
  );
}

export default Card;
