import React, { Suspense, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import { CircularProgress } from "@mui/material";
// import WebFlowComponent from './Components/WebFlowTest';
// import * as Webflow from './Components/js/webflow.js' //B
const Error404Page = React.lazy(() => import("./Pages/Error404Page"));
const StorePage = React.lazy(() => import("./Pages/StorePage")); //B
// const WebFlowComponent = React.lazy(() => import("./Pages/WebFlowComponent")); //B
// const StoreButton= React.lazy(() => import("./Components/StoreButton")); //B






const firebaseConfig = {
  apiKey: "AIzaSyDWVDbi-jrCwHFaO6O4jYdZgJCL80XLGRM",
  authDomain: "chatgpt-uncensored.firebaseapp.com",
  projectId: "chatgpt-uncensored",
  storageBucket: "chatgpt-uncensored.appspot.com",
  messagingSenderId: "818255807133",
  appId: "1:818255807133:web:38f0b521d04960a2604a74",
  measurementId: "G-N4HVLNRLMY",
  databaseURL: "https://chatgpt-uncensored-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
if (process.env.NODE_ENV !== "test") {
  const analytics = getAnalytics(app);
}

export const db = getDatabase(app);



function App() {
  // useEffect(() => {
  //   // Initialize Webflow scripts
  //   Webflow.init();
  // }, []);
  const [showWebFlowComponent, setShowWebFlowComponent] = useState(false);
  console.log('app rendering')
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <CircularProgress
            style={{
              margin: 0,
              position: "absolute",
              top: "50%",
              left: "50%",
              color: "#b3befe",
            }}
          />
        }
      >
        {/* <Example/> */}
  
        {/* <StoreButton onButtonClick={setShowWebFlowComponent} /> */}
      {/* {showWebFlowComponent && <WebFlowComponent />} */}
        <Routes>
          <Route path="/" element={<MainPage app={app} db={db} />} />

          {/* <Route path="/store" element={<StorePage app={app} />} /> */}
          {/* <Route path="/store" element={<Example app={app} />} /> */}

          <Route path="*" element={<Error404Page />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
