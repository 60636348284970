import React from 'react';
import './StripeTab.css';

function Tab({ activeTab, setActiveTabHandler }) {
  return (
    <div className={'Tab'}>
      <div
        className={activeTab === 'weekly' ? 'TabActive' : ''}
        onClick={() => setActiveTabHandler('weekly')}
      >
        Weekly
      </div>
      <div
        className={activeTab === 'monthly' ? 'TabActive' : ''}
        onClick={() => setActiveTabHandler('monthly')}
      >
        Monthly
      </div>
      <div
        className={activeTab === 'quarterly' ? 'TabActive' : ''}
        onClick={() => setActiveTabHandler('quarterly')}
      >
        3 Months
      </div>
    </div>
  );
}

export default Tab;
