import { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import ChatInputForm from "../Components/ChatInputForm";
import ChatMessage from "../Components/ChatMessage";
import NewChatButton from "../Components/NewChatButton";
import Hero from "../Components/Hero";
import GoogleAuthButtons from "../Components/GoogleAuthButtons";
import MessageHistory from "../Components/MessageHistory";

import SubscribeModal from "../Components/StripeModal";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
  signOut,
  onAuthStateChanged,

  sendSignInLinkToEmail,
  sendEmailVerification 
} from "firebase/auth";
import { collection, getDocs, doc, getDoc, getFirestore } from "firebase/firestore";
import { ref, push, get, set, update, increment } from "firebase/database";

import StoreButton from "../Components/StoreButton";
import "./MainPage.css";
import Disclaimer from "../Components/Disclaimer";
import TemperatureSlider from "../Components/TemperatureSlider";
import SignUpHeading from "../Components/SignUpHeading";
import NotSubscribedHeading from "../Components/NotSubscribedHeading";
import FreeTrial from "../Components/FreeTrial";
import ClearConversations from "../Components/ClearConversations";
import RateLimitError from "../Components/RateLimitError";
import ResponseFailedError from "../Components/ResponseFailedError";
import ModeChanger from "../Components/ModeChanger";
import CodingModeGuide from "../Components/CodingModeGuide";
import BugReportModal from "../Components/BugReportModal";
import { UnsubscribeModal } from "../Components/MsgHistoryAlertModal";

import { io } from 'socket.io-client';
// const socket = io('http://localhost:8080');

// socket.on('connect', () => {
//   console.log('Connected to Socket.IO server.');
// });


// import WebflowPricing from "./WebFlowTest";

function MainPage({ app, db }) {
    console.log('mainpage rendering')
  const provider = new GoogleAuthProvider(app);
  const auth = getAuth(app)
//   var user = auth.currentUser?.uid ?? null

  const [user, setUser] = useState(null);
  const userRef = useRef(user);
  useEffect(() => {
    userRef.current = user;
  }, [user]);


  const firestoreDB = getFirestore(app);
  const [conversationId, setConversationId] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [hasTrial, setHasTrial] = useState(false);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const [responseFailed, setResponseFailed] = useState(false);
  const [selectedModel, setSelectedModel] = useState("text-davinci-003");
  const [input, setInput] = useState("");

  const [temperature, setTemperature] = useState(0.5);

  const [displayName, setDisplayName] = useState([]);



        // Push the user's input to the database
    //   push(chatRef, {
    //     user: "me",
    //     message: input,
    //     timestamp: Date.now(),
    //   })
    //     .then(() => {})
    //     // Log the error if the write failed
    //     .catch((error) => {
    //       console.log("The write failed...", error);
    //     });
    // // }

    // clearInput(); // Clear the input field
    // setChatLog(chatLogNew); // Set the new chat log with user's input
    // setIsLoading(true);

    // Give the AI the last 5 messages for context as well as the recent input
    // const lastSix = chatLogNew.slice(Math.max(chatLogNew.length - 6, 0));
    // const messages = lastSix.map((message) => message.message).join("\n");


useEffect(() => {
    // When the authentication state changes (user logging in/out)
    onAuthStateChanged(auth, (user) => {

        setAuthLoading(false);
        if (user) {
        // Update user
        // user = true //B

        setUser(user);
        console.log('user is: ', user, " at ", Date.now(), "- setting conversation ID")
        if (user?.uid)  setConversationId(user.uid + Date.now());
        if (user.hasOwnProperty('displayName')) {
          const displayName = user.displayName;
          setDisplayName(displayName)
          // do something with the display name, e.g. set it on the UI
        }
        


        loadSubscription();
        handleTrialPeriod(user);
        checkTrialExpired(user);
        // Give new conversation id
        // setConversationId((user?.uid  ? user?.uid : "null") + Date.now());
        // Load users sub data
        // loadSubscription(); //B - reinstated
        // Create free trial if user has never had one
        // handleTrialPeriod(user);
        // Check if free trial is still active
        // checkTrialExpired(user);
      }
    });
  }, []);


    const checkTrialExpired = async (user) => {
        if (!user) return;
    
        const userRef = ref(db, `trials/${user.uid}`);
    
        get(userRef).then((snapshot) => {
          if (snapshot.val() === null) return;
          
    
          const trialData = snapshot.val();
          const createdAt = trialData.createdAt;
          const num_msgs = trialData.num_msgs;
          console.log('num_msgs: ', num_msgs)
          const now = Date.now();
    
          const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
          const trialDuration = now - createdAt;
    
          console.log(snapshot.val())
          console.log("created at, ", createdAt)
          console.log("now, ", now)
          console.log("trial duration, ", trialDuration)
          console.log("one day in milliseconds, ", oneDayInMilliseconds)
    
          // setHasTrial(true); //B
    
          // Return true if the trial has not yet expired (is less than one day old)
          if (trialDuration < oneDayInMilliseconds) {
            console.log("Trial is still active");
            setHasTrial(true);
          }
           if (num_msgs > 1) {
            console.log("num msgs greater than 5");
            setHasTrial(false);
          }
          else {
    
            console.log("Trial had existed but expired. Date.now(): ", Date.now())
          }
       
        });
      };

      function detectBrowser() {
        const userAgent = navigator.userAgent;
        let browser = '';
        let version = 0;
      
        if (userAgent.match(/Safari/i) && userAgent.match(/Macintosh/i) && !userAgent.match(/Chrome/i)) {
          browser = 'Safari';
          const versionMatch = userAgent.match(/Version\/([\d.]+)/);
          if (versionMatch && versionMatch[1]) {
            version = parseFloat(versionMatch[1]);
          }
        } else if (userAgent.match(/iPhone|iPod|iPad/i)) {
          browser = 'iOS';
          const versionMatch = userAgent.match(/Version\/([\d.]+)/);
          if (versionMatch && versionMatch[1]) {
            version = parseFloat(versionMatch[1]);
          }
        } else if (userAgent.match(/Firefox/i)) {
          browser = 'Firefox';
          const versionMatch = userAgent.match(/Firefox\/([\d.]+)/);
          if (versionMatch && versionMatch[1]) {
            version = parseFloat(versionMatch[1]);
          }
        }
      
        return { browser, version };
      }

      const [isIncognito, setIsIncognito] = useState(false);

      const checkIncognito = async () => {
        try {
          if ('storage' in navigator && 'estimate' in navigator.storage) {
            const { usage, quota } = await navigator.storage.estimate();
            const ratio = usage / quota;
      
            if (quota < 120000000 || (ratio && ratio < 0.9)) {
              console.log('ingognito mode detected')
              setIsIncognito(true);
            } else {
              console.log('ingognito mode NOT detected')
              setIsIncognito(false);
            }
          } else {
            console.log('ingognito mode NOT detected')
            setIsIncognito(false);
          }
        } catch (error) {
          console.log('ingognito mode NOT detected')
          setIsIncognito(false);
        }
      }
    
      useEffect(() => {
        checkIncognito();
      }, []);

      
    function handleNewChat() {
        if (user) {
          console.log('user uid: ', user.uid + Date.now)
          setConversationId(user.uid + Date.now());
        }
        clearInput();
        clearChat();
        setIsRateLimited(false);
        setResponseFailed(false);
      }
    
      // Redirect user to google login
      function handleLogin() {
        setIsLoggingIn(true);
        const { browser, version } = detectBrowser();
        if (
          (browser === 'Safari' && version >= 16.1) ||
          (browser === 'iOS' && version >= 16.1) ||
          (browser === 'Firefox' && version >= 109) ||
          (navigator.cookieEnabled === false) || isIncognito

        ) {
          console.log('Unsupported browser detected: - will use popup', browser, version);
          const userCred = signInWithPopup(auth, new GoogleAuthProvider());
        } else {
         signInWithRedirect(auth, provider);
        getRedirectResult(auth)
          .then((result) => {
            console.log("got auth redirect result ", result)
            console.log("Signed In as: ", auth.currentUser.email);
    
          })
          .catch((error) => {
            console.log(error.message);
          });
          // console.log('Unsupported browser detected:', browser, version);
        }
      }

      
      
    
      // Log user out, clear old messages and input
      function handleLogout() {
        signOut(auth)
          .then(() => {
            console.log("Logged out");
            clearInput();
            clearChat();
            setIsLoading(false);
            setIsRateLimited(false)
            setTimeout( () => {
              window.location.reload();
            }, 0)
            //then window.reload? //B
          })
          .catch((error) => {
            console.log(error);
          });
      }
      function clearChat() {
        setChatLog([]);
      }
    
      function clearInput() {
        setInput("");
      }
    
    
      // Check if a user has had a free trial and create one in database if they haven't
      const handleTrialPeriod = (user) => {
       if (user) { //B
    
          const userRef = ref(db, `trials/${user.uid}`);
          get(userRef).then((snapshot) => {
            if (snapshot.val() === null) {
              console.log("setting trial for : ", user.uid, " - the snapshot in the trials db is null")
              // create a new user document with the createdAt timestamp
              set(userRef, {
                createdAt: Date.now(),
                num_msgs: 0,
              })
                .then(() => {
                  console.log("New createdAt Started");
                  checkTrialExpired(user);
                })
                .catch((error) => {
                  console.log("The write failed...", error);
                });
            }
          });
       }
       else {
        console.log('user is ', (user), "in trial period")
       }
      }
      const incrementNumMsgs = (user) => {
        if (user) {
          const userRef = ref(db, `trials/${user.uid}`);
          get(userRef).then((snapshot) => {
            if (snapshot.val() !== null) {
              const currentNumMsgs = snapshot.val().num_msgs || 0;
              set(userRef, {
                num_msgs: currentNumMsgs + 1,
              })
                .then(() => {
                  console.log("num_msgs incremented for user: ", user.uid);
                })
                .catch((error) => {
                  console.log("The write failed...", error);
                });
            } else {
              console.log("User not found in trials database");
            }
          });
        } else {
          console.log("Invalid user object");
        }
      };

//     import React, { useEffect, useState } from 'react';
// import ChatMessage from './ChatMessage';
// import CircularProgress from '@mui/material/CircularProgress';
// import { useEffect, useRef, useState } from 'react';
// ...


const [socket, setSocket] = useState(null);
const [chatLog, setChatLog] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const lastStateRef = useRef({ prevChatLog: null, content: null });

useEffect(() => {
  const endpoint = process.env.REACT_APP_WEBSOCKET_ENDPOINT
    ? process.env.REACT_APP_WEBSOCKET_ENDPOINT
    : 'http://localhost:8080';


  // console.log('process env ws endpoint: ', process.env.REACT_APP_WEBSOCKET_ENDPOINT);
  // console.log('ws endpoint: ', endpoint);

  // const newSocket = io("http://localhost:8080");
  // const newSocket = io("https://backend-4gcx3znyfa-uw.a.run.app");
  console.log('using endpoint: ', endpoint)
  const newSocket = io(endpoint)
  console.log('using endpoint, ', endpoint, "we have a newSocket : ", newSocket);

  newSocket.on('message', async (event) => {
    const data = JSON.parse(event);

    console.log('inside onmessage');

    if (data.sender === 'bot') {
      if (userRef.current?.uid) {
        // Handle chat reference
      }

      if (data.type === 'start') {
        setIsLoading(true);
        const newChat = { user: 'gpt', message: '', index: chatLog.length };
        addMessage(newChat);
      } else if (data.type === 'stream') {
        console.log('inside stream. data.message: ', data.message);
        const content = data.message === '\n' ? '<br>' : data.message;
        await updateLastMessage(content);
        setIsRateLimited(false);
        setResponseFailed(false);
      } else if (data.type === 'info') {
        // handle info messages if needed
      } else if (data.type === 'end') {
        await updateLastMessage(undefined, true);
        setIsLoading(false);
      } else if (data.type === 'error') {
        setResponseFailed(true);
        setIsLoading(false);
        console.log('error');
      }
    } else {
      console.log('hit ws top-level else');
    }
  });
  setSocket(newSocket);


  return () => {
    newSocket.disconnect();
  };
}, []);


  const addMessage = (message) => {
    setChatLog((prevChatLog) => [...prevChatLog, message]);
   
    var chatRef = window.chatRef
    console.log("pushing chatref ", chatRef)
    // NEW B
  //   push(chatRef, {
  //     user: "gpt",
  //     message: message,
  //     timestamp: Date.now(),
  //   })
  //     .then(() => {})
  //     .catch((error) => {
  //       console.log("The write failed...", error);
  //     });

  };


  const updateLastMessage = async (content = '', streaming_complete=false) => {
    setChatLog((prevChatLog) => {
     if(streaming_complete) {
        const updatedChatLog = [...prevChatLog];
        updatedChatLog[updatedChatLog.length - 1].streaming_complete = true
        console.log('set streaming complete on msg')
        return updatedChatLog
     }
    
      if (
        lastStateRef.current.prevChatLog === prevChatLog &&
        lastStateRef.current.content === content
      ) {
        return prevChatLog; // Skip the update if the state is the same as the last run
      }

      const updatedChatLog = [...prevChatLog];
      updatedChatLog[updatedChatLog.length - 1].message += content ?? ""
      console.log('inside setchatlog - ', prevChatLog, "content: ", content, "updatedchatlog: ", updatedChatLog);

      // Store the current state for future comparisons
      lastStateRef.current = { prevChatLog, content };

      return updatedChatLog;
    });
  };

  // const sendMessage = (event) => {
  //   event.preventDefault();
  //   const message = document.getElementById('messageText').value;
  //   if (message === '') {
  //     return;
  //   }
  //   console.log("does this run?")
  //   ws.send(message);
  //   document.getElementById('messageText').value = '';

  //   setIsLoading(true);
  // };
  async function handleSubmit(e) {

    e.preventDefault();
    // Create reference to user's chat log in the database
    // const chatRef = ref(db, `messages/$mCvB4pZ1j9QxvOJR4xvb8n0Rvyh2/${conversationId}`);
    // Create reference to user's token usage in the database
    const chatRef = ref(db, `messages/${user.uid}/${conversationId}`);
    window.chatRef = chatRef

    // Create reference to user's token usage in the database


    // Check if the user is logged in
    //MAR 23 - now pushing on backend
    // if (user) {
    //   // Push the user's input to the database
    //   push(chatRef, {
    //     user: "me",
    //     message: input,
    //     timestamp: Date.now(),
    //   })
    //     .then(() => {})
    //     // Log the error if the write failed
    //     .catch((error) => {
    //       console.log("The write failed...", error);
    //     });
    // }
    // const tokenRef = ref(db, `token-usage/mCvB4pZ1j9QxvOJR4xvb8n0Rvyh2`); //Not currently tracking token usage
    // Add user's input to the chat log
    let chatLogNew = [...chatLog, { user: "me", message: `${input}` }];


    clearInput(); // Clear the input field
    setChatLog(chatLogNew); // Set the new chat log with user's input
    setIsLoading(true);

    // Give the AI the last 5 messages for context as well as the recent input
    const lastSix = chatLogNew.slice(Math.max(chatLogNew.length - 6, 0));
    const messages = lastSix.map((message) => message.message).join("\n");


    if (!messages || messages.length === 0) {
      console.log("messages is empty. sending input: ", input)
      socket.send(input);
      // ws.send(input);
     
    }
    else {
      console.log('sending ONLY 1 message now from frontend')
      const chatRef = ref(db, `messages/${user.uid}/${conversationId}`);
      socket.send(JSON.stringify({message: input, uid: userRef.current?.uid, displayName: displayName ?? false, chatRef: chatRef, conversation_id: conversationId}))
      // ws.send(JSON.stringify({message: input, uid: userRef.current?.uid, chatRef: chatRef, conversation_id: conversationId}))


      // ws.send(JSON.stringify({messages: messages, uid: userRef.current?.uid, chatRef: chatRef, conversation_id: conversationId}))
      // ws.send(messages);
    }

    return
}

// return (
//   <>
//     {/* Add your input form here with an onSubmit handler calling sendMessage */}
//     {!isRateLimited && (
//       <div className="chat-log">
//         {chatLog.map((message, index) => (
//           <ChatMessage
//             key={index}
//             message={message}
//             isLastMessage={index === chatLog.length - 1}
//             selectedModel={selectedModel}
//           />
//         ))}
//         {isLoading === true && (
//           <div className="circular-progress">
//             <CircularProgress style={{ color: '#b3befe' }} />
//           </div>
//         )}
//       </div>
//     )}
//   </>
// );







    // Fetch response from the backend
    // const response = await fetch(
    //   "https://chat-gpt-enhanced-backend.herokuapp.com",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       uid: user.uid,
    //     },
    //     // Post the last 6 messages (including the ai responses)
    //     // and the temperature for the current prompt
    //     body: JSON.stringify({
    //       message: messages,
    //       temperature: temperature,
    //       model: selectedModel,
    //     }),
    //   }
    // ).catch((error) => {
    //   // Show response failed error
    //   setResponseFailed(true);
    //   setIsLoading(false);
    //   throw new Error("API response was not okay");
    // });

    // // If user hit rate limit display error
    // if (response.status === 429) {
    //   setIsRateLimited(true);
    //   setIsLoading(false);
    //   throw new Error("Rate limit reached");
    // }

    // // Response was successful
    // setIsRateLimited(false);
    // setResponseFailed(false);

    // // Get the response data in JSON format
    // const data = await response.json();

    // Push the AI response to the database
//     push(chatRef, {
//       user: "gpt",
//       message: data.message,
//       timestamp: Date.now(),
//     })
//       .then(() => {})
//       .catch((error) => {
//         console.log("The write failed...", error);
//       });

//     // Push the token usage to the database
//     update(tokenRef, {
//       total_tokens_used: increment(data.token_usage),
//     })
//       .then(() => {})
//       .catch((error) => {
//         console.log("The write failed...", error);
//       });

//     // Set the chat log with the AI response
//     setChatLog([...chatLogNew, { user: "gpt", message: `${data.message}` }]);
//     setIsLoading(false);
//   }

  // Get users subscription data
  const loadSubscription = async () => {
    console.log('user from load sub:', auth?.currentUser)
    // console.log('userref-', userRef)
    // Get the current user
    const sub = auth.currentUser;
    // Get the reference to the user's subscriptions
    const ref = await getDocs(
      // collection(firestoreDB, `customers/mCvB4pZ1j9QxvOJR4xvb8n0Rvyh2/subscriptions`)
      collection(firestoreDB, `customers/${sub.uid}/subscriptions`)
    );

    // Set subscription details in state
    ref.forEach(async (doc) => {
      setSubscription({
        role: doc.data().role,
        current_period_end: doc.data().current_period_end,
        current_period_start: doc.data().current_period_start,
        ended_at: doc.data().ended_at,
        status: doc.data().status,
      });
    });
  };

  return (
    <div className="App">
      {/* Side menu (not visible on mobile) */}
      <aside className="side-menu">
        <NewChatButton handleNewChat={handleNewChat} isLoading={isLoading} />
        {/* Display message history if user is logged in */}
        {user && (
          <MessageHistory
            userId={user.uid}
            conversationId={conversationId}
            db={db}
          />
        )}

        <div className="white-buttons">
          <ClearConversations user={user} db={db} />
          <BugReportModal user={user} db={db} />
          {/* {(!subscription?.ended_at && subscription?.status !== "incomplete") ||
          subscription?.status === "complete" ? ( */}
            <UnsubscribeModal user={user} db={db}></UnsubscribeModal>
          {/* ) : null} */}
        </div>


        {/* <ModeChanger
          user={user}
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
          isLoading={isLoading}
          handleNewChat={handleNewChat}
        /> */}

        {/* Displays users free trial status */}
        {hasTrial && subscription?.role !== "premium" && user && <FreeTrial />}

        {/* Takes user to the store, only displayed if user is logged in */}
        {/* <StoreButton user={user} /> */}

        {/* Login/logout buttons */}
        {!authLoading && (
          <GoogleAuthButtons
            user={user}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
          />
        )}
      </aside>

      {/* Chat box */}
      <section className="chat-box">
        {/* New chat button for mobile only */}
        <div className="mobile-new-chat-button">
          <NewChatButton isLoading={isLoading} handleNewChat={handleNewChat} />
        </div>

        {/* Only display hero if theres no chats and if user isn't rate limited */}
        {chatLog.length === 0 && !isRateLimited && <Hero />}

        {/* Store button for mobile only */}
        {/* <div className="mobile-store-button">
          {chatLog.length === 0 && <StoreButton user={user} />}
        </div> */}

        {/* Chat log (the actual messages) */}
        {/* {!isRateLimited && ( */}
          <div className="chat-log">
            {chatLog.map((message, index) => (

                // <ChatMessage
                // message={message.message}
                // lightMode={"dark"}
                // ></ChatMessage>

              <ChatMessage
                key={index}
                message={message}
                isLastMessage={index === chatLog.length - 1}
                selectedModel={selectedModel}
              />
            ))}
            {isLoading === true && (
              <div className="circular-progress">
                <CircularProgress style={{ color: "#b3befe" }} />
              </div>
            )}
          </div>
        {/* )} */}

        {/* Error messages */}
        {isRateLimited && <RateLimitError />}
        {responseFailed && <ResponseFailedError />}

        {/* 
          Login button for mobile only 
          Right now its set up to only display the login button need to rework
          the ui for mobile 
        */}
        {!user && !authLoading && (
          <div className="mobile-login-button">
            <GoogleAuthButtons
              user={user}
              handleLogin={handleLogin}
              handleLogout={handleLogout}
            />
          </div>
        )}

        {/* If user is subscribed or has trial display temperature slider */}
        {/* {hasTrial ||
        (subscription?.role === "premium" &&
          !subscription?.ended_at &&
          !isRateLimited &&
          selectedModel === "text-davinci-003" &&
          subscription?.status !== "incomplete") ? (
          <TemperatureSlider setTemperature={setTemperature} user={user} />
        ) : null} */}

        {/* If the user is not signed in display sign up heading */}
        {!user && !authLoading && (
          <SignUpHeading handleLogin={handleLogin} isLoggingIn={isLoggingIn} />
        )}

        {/* If user is in code mode show coding mode guide */}
        {hasTrial ||
        (subscription?.role === "premium" &&
          !subscription?.ended_at &&
          !isRateLimited &&
          user &&
          chatLog.length === 0) ? (
          <CodingModeGuide selectedModel={selectedModel} />
        ) : null}

            
           { /* does this work? */}
        {/* <div className="mobile-store-button">
          {chatLog.length === 0 &&   <NotSubscribedHeading user={user}/>}
        </div> */}
        

        {/* If the user isn't subscribed display the visit store heading */}
        {subscription?.role !== "premium" && user && !hasTrial && (
        // ( ()=>  { console.log("Debug info: Non-premium user without trial")})()
          <NotSubscribedHeading user={user}/>
        )}

        {/* Loading spinner when user is logging in */}
        {authLoading && <CircularProgress style={{ color: "#b3befe" }} />}

        {/* If the user is subscribed or has trial display the chat input form */}
        {hasTrial ||
        (subscription?.role === "premium" &&
          !subscription?.ended_at &&
          !isRateLimited &&
          subscription?.status !== "incomplete") ? (
          <ChatInputForm
            input={input}
            setInput={setInput}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            user={user}
            handleLogin={handleLogin}
            selectedModel={selectedModel}
          />
        ) : null} 

        <Disclaimer />
      </section>
    </div>
  );
}

export default MainPage;