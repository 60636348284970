// src/modalContext.js
import { createContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [showWebFlowComponent, setShowWebFlowComponent] = useState(false);

  return (
    <ModalContext.Provider value={{ showWebFlowComponent, setShowWebFlowComponent }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
