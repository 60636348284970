import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./NotSubscribedHeading.css";
import SubscribeModal from "./StripeModal";

function NotSubscribedHeading({user}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('user: ', user)
      setShow(true);
    }, 400);
    return () => clearTimeout(timer);
  });

  return show ? (

    <>
      <motion.h4
        // fade messages in
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="not-subscribed-heading"
      >
       
        Visit The Store to Start
        <SubscribeModal user={user} />

      </motion.h4>
    </>

  ) : null;
}

export default NotSubscribedHeading;
